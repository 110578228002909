const FORMS_URL = 'https://editor.wix.com/_api/wix-form-builder-web'
const PLATFORMIZED_FORMS_URL = `${FORMS_URL}/v1`
const PREMIUM_RESTRICTIONS_ENDPOINT = `premium/restrictions`

export const PREMIUM_RESTRICTIONS_URL = `${PLATFORMIZED_FORMS_URL}/${PREMIUM_RESTRICTIONS_ENDPOINT}`
export const PAYMENT_RESTRICTION = 'PAYMENT_FORM'

export const URL_REGEX = '^((https?|ftp)://)?[^\\s/$.?#]*\\.[^\\s]*$'
export const EMAIL_REGEX = '^.+@.+\\.[a-zA-Z]{2,63}$'

export const MEMBER_ALREADY_EXISTS_KEY = 'registrationForm.error.memberAlreadyExists'
export const INVALID_PASSWORD_KEY = 'registrationForm.error.invalidPassword'
export const GENERAL_REGISTRATION_ERROR_MESSAGE_KEY = 'registrationForm.error.general'

