import { GENERAL_REGISTRATION_ERROR_MESSAGE_KEY, INVALID_PASSWORD_KEY, MEMBER_ALREADY_EXISTS_KEY } from './constants'

export const REGISTRATION_ERROR_TYPES = [
  {
    pattern: new RegExp('member with email (.*?) already exists in collection'),
    translationKey: MEMBER_ALREADY_EXISTS_KEY,
  },
  {
    pattern: new RegExp('Password contains non Ascii characters'),
    translationKey: INVALID_PASSWORD_KEY,
  }]

export const getRegistrationFailureErrorMessageKey = (errorMessage: string) => {
  for (const errorType of REGISTRATION_ERROR_TYPES) {
    if (errorType.pattern.test(errorMessage)) {
      return errorType.translationKey
    }
  }
  return GENERAL_REGISTRATION_ERROR_MESSAGE_KEY
}
