import * as _ from 'lodash'
import { PAYMENT_RESTRICTION, PREMIUM_RESTRICTIONS_URL } from './constants'
import { fetchRetry } from '../../utils/fetch-utils'

// this method is being used only in preview mode in editor although imported in viewer-app
export const isPaymentAllowed = ravenInstance => async instance => {
  const headers = { Authorization: instance, 'Content-Type': 'application/json' }
  const response = await fetchRetry(ravenInstance)(PREMIUM_RESTRICTIONS_URL, {
    method: 'GET',
    headers,
  })

  let allowedFeatures = []

  try {
    const restrictions = await response.json()
    allowedFeatures = _.get(restrictions, 'allowedFeatures')
  } catch {}

  return _.includes(allowedFeatures, PAYMENT_RESTRICTION)
}
