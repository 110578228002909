import * as _ from 'lodash'
import { FIELDS_ROLES, FIELDS } from '../constants/roles'
import { initSubmitUtils } from './submit-utils'
import { CRM_TYPES } from '../constants/crm-types-tags'
import { EVENTS } from '../constants/bi-viewer'
import { URL_REGEX, EMAIL_REGEX } from '../viewer-app/services/constants'
import Experiments from '@wix/wix-experiments'
import { FORMS_APP_DEF_ID } from '../constants'
import { FieldPreset } from '../constants/field-types'

export const escapeRegExp = str => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') //eslint-disable-line no-useless-escape
export const innerText = str => _.trim(str.replace(/\s*<[^>]*>\s*/gm, ''))

export const getValidCollectionId = (formId: string, collectionId: string) => {
  if (!collectionId) return

  const [compId, realCollectionId] = collectionId.split('_')
  return !collectionId.startsWith('comp')
    ? collectionId
    : compId === formId
    ? realCollectionId
    : undefined
}

export const findPlugin = (plugins, pluginId) => _.find(plugins, plugin => plugin.id === pluginId)

export const getFieldType = field => {
  return field.type.split('.')[1]
}

export const replaceMessageInnerText = ($message, replaceFunc: (string) => string): void => {
  const messageText = _.get($message, 'html')
  $message.html = replaceFunc(messageText)
}

const isFieldType = (fieldType: string) => field => field.type === `$w.${fieldType}`
const FILES_TYPE = {
  IMAGE: 'Image',
  DOCUMENT: 'Document',
  VIDEO: 'Video',
}

const ACCEPTABLE_FILE_TYPES = {
  [FILES_TYPE.IMAGE]: '.jpeg,.png,.jpg',
  [FILES_TYPE.DOCUMENT]: '.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.odp,.odt,.epub',
  [FILES_TYPE.VIDEO]:
    '.avi,.mpeg,.mpg,.mpe,.mp4,.mkv,.webm,.mov,.ogv,.vob,.m4v,.3gp,.divx ,.xvid,.mxf,.wmv',
}

export const isSignatureField = isFieldType('SignatureInput')
export const isUploadButton = isFieldType('UploadButton')
export const isCaptchaField = isFieldType('Captcha')
export const isRadioGroup = isFieldType('RadioButtonGroup')
export const isCheckbox = isFieldType('Checkbox')
export const isNumber = field => isFieldType('TextInput')(field) && field.inputType === 'number'
export const isDatePicker = isFieldType('DatePicker')

export const toMiliseconds = sec => sec * 1000

const getFileExtension = file => file.split('.').pop()

export const addContactsValidation = ($w, ravenInstance) => {
  const fields = initSubmitUtils(ravenInstance).getFields({ $w, roles: FIELDS_ROLES })
  const maxLengthValidation = (field, crmType) => {
    const crmMaxLength = _.get(CRM_MAX_LENGTH, crmType)
    if (crmMaxLength) {
      const fieldMaxLength = _.isNumber(field.maxLength) ? field.maxLength : Infinity
      field.maxLength = Math.min(fieldMaxLength, crmMaxLength)
    }
  }
  const pattrenValidation = (field, crmType) => {
    if (CRM_PATTERNS[crmType]) {
      field.pattern = CRM_PATTERNS[crmType]
    }
  }

  fields.forEach(field => {
    const crmType = _.get(field, 'connectionConfig.crmType')
    maxLengthValidation(field, crmType)
    pattrenValidation(field, crmType)
    if (!isUploadButton(field)) {
      return
    }
    field.onChange(() => {
      if (
        _.isEmpty(field.value) ||
        _.includes(
          ACCEPTABLE_FILE_TYPES[field.fileType].split(','),
          `.${getFileExtension(field.value[0].name)}`
        )
      ) {
        return
      }
      field.reset()
    })
  })
}

//https://github.com/wix-private/crm/blob/master/user-activity-domain/src/main/scala/com/wixpress/useractivity/entities/ContactUpdate.scala
export const CRM_MAX_LENGTH = {
  [CRM_TYPES.FIRST_NAME]: 100,
  [CRM_TYPES.ADDRESS]: 250,
  [CRM_TYPES.EMAIL]: 250,
  [CRM_TYPES.COMPANY]: 100,
  [CRM_TYPES.POSITION]: 100,
  [CRM_TYPES.LAST_NAME]: 100,
  [CRM_TYPES.PHONE]: 50,
}

export const CRM_PATTERNS = {
  [CRM_TYPES.EMAIL]: EMAIL_REGEX,
  [CRM_TYPES.WEBSITE]: URL_REGEX,
}

export const getCaptchaField = $w => $w(`@${FIELDS.ROLE_FIELD_RECAPTCHA}`)[0] || null

export const onCaptchaVerify = (
  logger,
  { formRefId, visitorId }: { formRefId: string; visitorId: string }
) => logCaptchEvent(logger, { formRefId, visitorId }, captchaStatus.VERIFIED)

export const onCaptchaTimeout = (
  logger,
  { formRefId, visitorId }: { formRefId: string; visitorId: string }
) => logCaptchEvent(logger, { formRefId, visitorId }, captchaStatus.EXPIRE)

const logCaptchEvent = (
  logger,
  { formRefId, visitorId }: { formRefId: string; visitorId: string },
  authentication_status: captchaStatus
) =>
  logger.log({
    evid: EVENTS.CAPTCHA_ACTION,
    form_comp_id: formRefId,
    visitor_id: visitorId,
    authentication_status,
  })

export enum captchaStatus {
  VERIFIED = 'Verified',
  EXPIRE = 'Expire',
}

const PREVIEW_MODE = 'Preview'

export const isTemplate = wixLocation => !wixLocation.baseUrl
export const isPreviewMode = wixWindow => wixWindow.viewMode === PREVIEW_MODE
export const shouldSendData = (wixLocation, wixWindow, experiments: Experiments) =>
  !isTemplate(wixLocation) && (experiments.enabled('specs.cx.FormBuilderSubmitFormInPreview') || !isPreviewMode(wixWindow))

export const getBaseUrl = wixLocation => {
  const urlDirs = wixLocation.baseUrl.split('/')
  let baseUrl = urlDirs.slice(0, urlDirs.length - 1).join('/')
  if (baseUrl === 'https:/' || baseUrl === 'http:/') {
    baseUrl = wixLocation.baseUrl // TODO fix with amitay
  }

  return baseUrl
}

export const getInstance = wixSite => wixSite.getAppToken(FORMS_APP_DEF_ID)

export const findFieldByPresetType = (fields: any[], role: FieldPreset): any =>
  _.find(fields, field => _.get(field, 'connectionConfig.fieldType') === role)

export const addRegistrationFormValidation = ($w, ravenInstance) => {
  const REQUIRED_REGISTRATION_TEXT_ROLES = [
    FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL,
    FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD
  ]

  const requiredInputTextFields = initSubmitUtils(ravenInstance).getFields({ $w, roles: REQUIRED_REGISTRATION_TEXT_ROLES })

  requiredInputTextFields.forEach(field => {
    field.required = true
    field.resetValidityIndication()
  })
}